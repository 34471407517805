<template>
    <img src="@/src/assets/menu.svg" alt="menu suspenso">
</template>

<script>

</script>

<style scoped>
img {
    cursor: pointer;
}
</style>