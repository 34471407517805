<template>
  <LoveHeader></LoveHeader>
  <LoveFormHire></LoveFormHire>
</template>

<script>
import LoveHeader from '@/src/components/LoveHeader.vue'
import LoveFormHire from '@/src/components/LoveFormHire.vue'
export default {
  name: 'App',
  components: {
    LoveHeader,
    LoveFormHire
  }
}
</script>

<style>
@import url('https://fonts.cdnfonts.com/css/cabinet-grotesk');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
body {
  background-color: #FBFBFB;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #FBFBFB;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
</style>
