<template>
    <header>
        <LoveLogo></LoveLogo>
        <LoveMenu></LoveMenu>
    </header>
</template>

<script>
import LoveLogo from '@/src/components/LoveLogo.vue'
import LoveMenu from '@/src/components/LoveMenu.vue'
export default {
  components: {
    LoveLogo,
    LoveMenu
  }
}
</script>

<style scoped>
header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 10px;
}
</style>