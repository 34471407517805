<template>
    <section>
        <h2>Demonstre carinho para seu amor</h2>
        <form action="" class="form-part">
            <div class="radio-part">
                <div class="radio-box">
                    <input type="radio" id="first-plan" name="plan" value="starter" class="custom-radio" required  v-model="plan"/>
                    <label for="first-plan">1 ano, 4 fotos <br><span>R$29,99</span></label>
                </div>
                <div class="radio-box">
                    <input type="radio" id="second-plan" name="plan" value="end" class="custom-radio" v-model="plan"/>
                    <label for="second-plan">2 anos, 8 fotos + musica <br><span>R$49,99</span></label>
                </div>
            </div>
            <div class="input-text-box">    
                <label for="email">Email para receber seu site</label>
                <input type="text" v-model="email" id="email" name="email" placeholder="name@example.com" class="input-text" required>
            </div>
            <div class="input-text-box">
                <label for="your-name">Seu nome</label>
                <input type="text" v-model="yourName" id="your-name" name="your-name" placeholder="Insira aqui" class="input-text" required>
            </div>
            <div class="input-text-box">
                <label for="loves-name">Nome do seu amor</label>
                <input type="text" id="loves-name" v-model="lovesName" name="loves-name" placeholder="Insira aqui" class="input-text" required>
            </div>
            <div class="input-text-box">
                <label for="start-date">Início do relacionamento</label>
                <input type="date" id="start-date" v-model="startDate" name="start-date" class="input-text" required>
            </div>
            <div class="input-text-box">
                <label for="hour-estimated">Hora estimada do inicio</label>
                <input type="time" id="hour-estimated" v-model="hourEstimated" name="hour-estimated" class="input-text" required>
            </div>
            <div class="input-text-box">
                <label for="love-text">Escreva uma mensagem para seu amor</label>
                <textarea id="love-text" name="love-text" v-model="loveText" placeholder="Escreva uma mensagem carinhosa para seu amor 💟" rows="4" cols="37"></textarea>
            </div>
            <div>
                <label for="love-img" class="custom-file-upload"><img src="@/src/assets/camera.svg" alt="camera">Escolher fotos de casal</label>
                <input type="file" id="love-img" name="love-img" v-on:change="onFileChange" multiple accept="image/jpg, image/jpeg, image/png" required>
            </div>
            <div>
                <button @click="submit" class="submit">Criar site</button>
            </div>
        </form>
    </section>
</template>

<script>
export default {
    data() {
        return {
            plan: '',
            email: '',
            yourName: '',
            lovesName: '',
            startDate: '',
            hourEstimated: '',
            loveText: '',
        }
    },
    methods: {
        onFileChange(e) {
            console.log(e.target.files)
        }
  }
}

</script>

<style scoped>
section {
    padding: 0 25px;
}
h2 {
    color: #E14942;
    font-family: 'Cabinet Grotesk', sans-serif;
    text-align: start;
    margin: 0;
}

form {
    margin-top: 45px    ;
}

.form-part {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.radio-part {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.custom-radio:after {
    width: 13px;
    height: 13px;
    border-radius: 13px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #E4E4E7;
}

.custom-radio {
    cursor: pointer;
}

.custom-radio:checked:after {
    width: 13px;
    height: 13px;
    border-radius: 13px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #DF605A;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #DF605A;
}

.radio-box {
    border: #E4E4E7 1px solid;
    border-radius: 5px;
    padding: 17px;
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 10px;
    cursor: pointer;
}

.radio-box:has(.custom-radio:checked) {
    border: #E14942 1px solid;
}

.radio-box label {
    color: #71717A;
    font-family: 'Cabinet Grotesk', sans-serif;
    font-weight: 600;
    text-align: start;
    cursor: pointer;
    width: 100%;
}

.radio-box:has(.custom-radio:checked) label  {
    color: #E14942;
}
.radio-box label span {
    font-weight: 400;
}

.input-text-box {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: start;
    font-family: "Inter", sans-serif;
    color: #000000;
    font-size: 14px;
}

.input-text {
    border: 1px solid #E4E4E7;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    padding: 0;
    margin: 0;
}

textarea {
    width: 100%;
    border: 1px solid #E4E4E7;
    border-radius: 5px;
    padding: 0;
    margin: 0;
}

textarea::placeholder {
    font-family: "Inter", sans-serif;
    padding: 5px;
}

.input-text::placeholder {
    padding-left: 10px;
    color: #71717A;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    width: 93%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #E14942;
    padding: 6px 12px;
    cursor: pointer;
    font-family: "Inter", sans-serif;
    border-radius: 5px;
    color: #FFFFFF;
    height: 40px;
    font-weight: 300;
}
input:focus {
    border-color: #DF605A;
}

.submit {
    width: 100%;
    background-color: #E4E4E7;
    color: #000000;
    text-decoration: none;
    border: none;
    height: 40px;
    border-radius: 5px;
    font-family: "Inter", sans-serif;
    font-size: 15px;
}
</style>