<template>
    <div class="logo">
        <img src="@/src/assets/logo.png" alt="Love Mais">
        <h1 class="love-title">lovemais</h1>
    </div>
</template>

<script>

</script>

<style scoped>
.logo {
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 15px 15px 0 15px;
}
img {
    width:32px;
    height: 29px;
}
h1 {
    margin: 0px;
}
.love-title {
    color: #E14942;
    font-family: 'Cabinet Grotesk', sans-serif;
}

</style>